:root {
  /* Light theme variables */
  --main-charcoal: #333333;
  --main-offwhite: #f5f5f5;
  --main-gray: #303841;
  --bg-primary: var(--main-offwhite);
  --text-primary: var(--main-charcoal);
  --nav-bg: rgba(245, 245, 245, 0.75);
  --project-bg: var(--main-offwhite);
  --spinner-border: var(--main-charcoal);
  --card-shadow: rgba(0, 0, 0, 0.1);

  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-theme='dark'] {
  /* Dark theme variables */
  --bg-primary: #1a1a1a;
  --text-primary: #f5f5f5;
  --nav-bg: rgba(26, 26, 26, 0.75);
  --project-bg: #2d2d2d;
  --spinner-border: var(--main-offwhite);
  --card-shadow: rgba(255, 255, 255, 0.1);
}

* {
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background: var(--bg-primary); 
}

@media (max-width: 75em) {
  html {
    font-size: 60%;
  }
}

@media (max-width: 61.25em) {
  html {
    font-size: 58%;
  }
}

@media (max-width: 28.75em) {
  html {
    font-size: 55%;
  }
}

body {
  font-family: 'courier';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: background-color 0.3s ease, color 0.3s ease;
}

h1,
h2 {
  font-family: 'murmure';
  font-weight: 700;
  text-align: center;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4.2rem;
}

ul {
  list-style: none;
}

.additional-skills {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 80%;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0;
  position: static;
}

a {
  text-decoration: none;
  color: var(--main-charcoal);
}

img {
  display: block;
  width: 100%;
}

#logo {
  font-size: 4.4rem;
  font-family: roboto; 
  position: absolute; 
  z-index: 6; 
  left: 1.5%; 
  top: 8%; 
  color: var(--text-primary);
}

#logo:hover {
  text-decoration: underline;
   transform: scale(1.15);
   color: var(--text-primary);
}

.nav {
  display: flex;
  position: fixed;
  justify-content: center; 
  top: 0;
  left: 0;
  width: 100%;
  background: var(--nav-bg);
  backdrop-filter: blur(10px);
  z-index: 10;
  opacity: 75%; 
}

.nav-list {
  display: flex;
  margin-right: 2rem;
}

@media (max-width: 28.75em) {
  .nav {
    justify-content: center;
  }

  .nav-list {
    margin: 0 1rem;
  }
}

.nav-list a {
  display: block;
  font-size: 2.6rem;
  padding: 2rem;
  color: var(--text-primary);
}

li a {
  transition: transform 0.3s ease, text-decoration 0.3s ease, color 0.3s ease; 
}

li a:hover {
  transform: scale(1.3);
  text-decoration: underline;
  color: var(--text-primary);
}

li {
  padding-left: 2rem;
}

@media only screen and (max-width: 767px) {
  .nav-list > li {
    padding: 0; 
    margin: 0 -1.2rem; 
  }
  .nav-list a {
    font-size: 1.5rem;
  }
  .nav-list {
    margin-top: 1rem;
  }
  .nav > a {
    display: none; 
  }
}

.welcome-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 20rem;
  background-color: var(--bg-primary);
}

.welcome-section > img {
  width: 70%; 
  height: width;
  max-width: 450px;
  max-height: 450px;
  margin-top: 10vh; 
  margin-bottom: 5vh; 
  position: relative; 
  top: -5vh; 
  transition: transform 1s ease-in-out; 
}

.welcome-section > img:hover {
transform: scale(1.1); 
}

@media only screen and (max-width: 767px) {
  .welcome-section #sentence{
    width: 90%;
    text-align: center;
  }
  .welcome-section {
    margin-top: 15rem; 
  }
  .welcome-section h1 {
    margin-bottom: 2rem; 
  }
}

#sentence {
  font-size: 2.6rem;
  font-weight: 200;
  font-style: italic;
  color: var(--text-primary);
}

.welcome-section > #sentence, h1 {
 position: relative; 
 top: -2vh;  
}

#blurb {
  font-family: courier; 
  text-align: center; 
  max-width: 60%; 
  border-radius: 20px;
  padding: 10px; 
  box-shadow: 0 2px 8px var(--card-shadow);
}

#blurb:hover {
  background: var(--text-primary);
  color: var(--bg-primary);
}

.skills-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: var(--bg-primary);
  position: relative;
  margin-top: 20rem;
  min-height: fit-content;
  overflow: visible !important;
}

.skills-section > h2 {
  max-width: 450px;
  margin: 0 auto 6rem auto;
  font-size: 36px;
  font-weight: bold;
  color: var(--text-primary);
  padding: 10px 20px;
  background-color: var(--project-bg);
  border-radius: 10px;
  animation: headerAnimation 2s ease-in-out infinite;
  width: 100%;
}

@keyframes headerAnimation {
  0% {
    transform: translateY(0);
    box-shadow: 0 2px 4px var(--card-shadow);
  }
  25% {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px var(--card-shadow);
  }
  50% {
    transform: translateY(0);
    box-shadow: 0 10px 12px var(--card-shadow);
  }
  75% {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px var(--card-shadow);
  }
  100% {
    transform: translateY(0);
    box-shadow: 0 2px 4px var(--card-shadow);
  }
}

.skills-section ul,
.skills-list,
.additional-skills {
  width: 100%;
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0;
  padding: 2rem 0;
  list-style: none;
  position: static;
  overflow: visible !important;
}

.skills-section ul li,
.skills-list li {
  margin: 1rem;
  position: static;
  overflow: visible !important;
}

.skills-section > h3 {
  position: static;
  margin: 2rem 0;
  width: 100%;
  max-width: 450px;
  text-align: center;
}

@media (max-width: 28.75em) {
  .skills-section > h2 {
    width: 90%;
  }
  .skills-section > h3 {
    width: 90%;
    text-align: center;
  }
}

.item {
  font-size: 2rem;
  padding: 10px; 
  background: var(--project-bg);
  transition: transform 0.3s ease-out;
  border-radius: 10px;
}

.item:hover {
  background: var(--text-primary);
  color: var(--bg-primary); 
}

.skills-section > ul {
  position: relative; 
  top: 4vh;
  margin-top: 2rem; 
}

.image-container {
  position: relative;
}

.label {
  position: absolute;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
  background-color:var(--main-gray); 
  color: white;
  padding: 8px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 12px;
  text-align: center;
  z-index: 1;
}

.image-container:hover .label {
  opacity: 1;
}

.icons:hover {
  transform: scale(1.3); 
}

.icons {
  transition: transform 0.3s ease-in-out; 
width: 50px; 
height: 50px; 
}

.projects-section {
  text-align: center;
  padding: 10rem 2rem;
  background: var(--bg-primary);
  position: relative; 
  margin-top: 20rem;
}

@media only screen and (max-width: 767px) {
  .projects-section {
    margin-top: 20rem;
  }
}

.projects-section-header {
  max-width: 450px; 
  margin: 0 auto 6rem auto;
  font-size: 36px;
  font-weight: bold;
  color: var(--text-primary);
  padding: 10px 20px;
  background-color: var(--project-bg);
  border-radius: 10px;
  animation: headerAnimation 2s ease-in-out infinite;
  width: 100%; 
}

@media (max-width: 28.75em) {
  .projects-section-header {
    font-size: 4rem;
    width: 95%; 
  }
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 4rem;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0 2rem;
  box-sizing: border-box;
}

@media (max-width: 30.625em) {
  .projects-section {
    padding: 6rem 1rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
  }
}

.project {
  background: var(--project-bg);
  box-shadow: 1px 1px 2px var(--card-shadow);
  border-radius: 20px;
  transition: transform 0.3s ease;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.project .image-wrapper {
  height: calc(100% - 6.8rem);
  width: 100%;
  margin: 0;
  position: relative;
}

.project-title {
  padding: 2rem;
  margin: 0;
  font-size: 2rem;
  color: var(--text-primary);
  background: var(--project-bg);
}

.code {
  color: transparent;
  transition: color 0.3s ease-out;
}

.project:hover .code {
  color: grey;
}

.project:hover {
  transform: scale(1.1); 
}

.project .spinner-container {
  height: 100%;
  background: var(--project-bg);
}

.btn {
  position: relative;
  top: 3vh; 
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 5px;
}

.btn-resume {
  font-size: 3rem;
  background: var(--project-bg);
  color: var(--text-primary);
  transition: transform 0.3s ease-out;
}

.btn-resume:hover {
  background: var(--text-primary);
  color: var(--bg-primary);
}

.btn-resume:hover > i {
  transform: translateX(2px);
}

.btn-resume > i {
  margin-left: 10px;
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 12vh;
  padding: 0 2rem;
  background: var(--bg-primary);
}

@media only screen and (max-width: 767px) {
  .contact-section {
    margin-top: 5rem;
    margin-bottom: 75px; 
  }
}

.contact-links {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  margin-top: 4rem;
  flex-wrap: wrap;
  position: relative; 
  top: -8vh; 
}

.contact-details {
  font-size: 2.4rem;
  transition: transform 0.3s ease-out;
  color: var(--text-primary);
}

.contact-details:hover {
  transform: translateY(8px);
}

::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-charcoal);
  border-radius: 12px; 
  opacity: 50%;
}

::-webkit-scrollbar-track {
background-color: var(--main-offwhite);
}

/* Improve mobile responsiveness */
@media (max-width: 480px) {
  .projects-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
  
  .nav-list {
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: center;
  }
}

.image-wrapper {
  position: relative;
  width: 70%;
  max-width: 450px;
  min-height: 200px;
  margin-top: 10vh;
  margin-bottom: 5vh;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--bg-primary);
  border-top: 5px solid var(--spinner-border);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Theme toggle styles */
.theme-toggle-container {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 2.2rem;
  transition: transform 0.3s ease;
}

.theme-toggle:hover {
  transform: scale(1.1);
}

/* Update other components for dark mode */
.skills-section,
.projects-section,
.contact-section {
  background-color: var(--bg-primary);
}

.project-title {
  color: var(--text-primary);
}

.btn-resume {
  background: var(--project-bg);
  color: var(--text-primary);
}

#blurb:hover {
  background: var(--text-primary);
  color: var(--bg-primary);
}

.contact-details {
  color: var(--text-primary);
}

/* Update scrollbar for dark mode */
[data-theme='dark'] ::-webkit-scrollbar-thumb {
  background-color: #666;
}

[data-theme='dark'] ::-webkit-scrollbar-track {
  background-color: #1a1a1a;
}

/* Update container widths to prevent overflow */
.projects-section,
.welcome-section,
.skills-section,
.contact-section {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Ensure grid doesn't cause overflow */
.projects-grid {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0 2rem;
  box-sizing: border-box;
}

/* Ensure all child elements can't cause scroll */
.skills-section *,
.skills-section *::before,
.skills-section *::after {
  overflow: visible !important;
}